<template>
  <div class="warp"
       v-loading="Loading"
       element-loading-text="拼命加载中"
       element-loading-spinner="el-icon-loading"
       element-loading-background="rgba(0, 0, 0, 0.8)">
    <div class="tab tab-top">
      <div class="tab-item toptab active">奖励</div>
      <div class="tab-item toptab">任务</div>
      <div class="tab-item toptab"
           :class="{ hiddenState: plug_makes.type == 1 }">
        钻石兑换
      </div>
      <div>
        <p style="padding: 6px 6px; float: left; margin-left: 14px">
          <span>钻石：{{ plug_makes.diamond }}

            <el-tooltip v-if="plug_makes.type == 0 || plug_makes.type == 2"
                        class="item"
                        effect="dark"
                        content=""
                        placement="bottom">
              <div slot="content"
                   style="padding: 10px 10px">
                <div style="text-align: center; margin-bottom: 10px;">
                  请复制下面地址浏览器打开
                </div>
                <input style="width: 96%;color: aliceblue; cursor:pointer"
                       type="text"
                       :value="plug_makes.pay_url" />
                       <div style="text-align: center;margin-top: 10px;"><span style="background: #001ce0;padding: 4px 10px;border: 1px solid #707070;border-radius: 3px;cursor:pointer;" @click="CloudDiskCopy(plug_makes.pay_url)">复制</span></div>
              </div>
              <span style="color: #797979; margin-right: 10px;cursor:pointer">购买</span>
            </el-tooltip>
            <el-tooltip v-if="plug_makes.type == 0 || plug_makes.type == 1"
                        class="item"
                        effect="dark"
                        content=""
                        placement="bottom">
              <div slot="content"
                   style="padding: 10px 10px">
                <div style="text-align: center; margin-bottom: 10px">
                  挑战以下任意一个副本即可获得
                </div>
                <p v-for="(vcc, i) in plug_makes.roomdata"
                   :key="i">
                  <span style="color: #ce4f4f">通关【{{ vcc.name }}】</span>
                </p>
              </div>
              <span style="color: #797979; margin-right: 10px; cursor:pointer">获得</span>
            </el-tooltip>
          </span>
        </p>
        <p style="padding: 6px 6px; float: right">
          <span>当前角色：{{ plug_makes.charac_name }}
            <span style="color: #797979;cursor:pointer"
                  @click="LoginDisplay = 'display:block'">《换绑》</span></span>
        </p>
      </div>
    </div>
    <div class="main">
      <div class="header">
        <div class="tier"></div>
        <div class="header-right">
          <div class="header-title">
            阿拉德探险记第{{ plug_makes.season }}季
          </div>
          <div class="level">
            <span class="lv">{{ plug_makes.plug_makes_level }}</span>
          </div>
          <div class="vip"></div>
          <div class="user">
            <div class="name">
              <span style="font-size: 16px; color: #718641; margin-left: 70px">:距离结束还有<span style="color: #ff0a0a">{{
                                    plug_makes.kf_day
                                    }}</span>天</span>
            </div>
            <div class="point"
                 style="color: #885d5d; font-size: 14px">
              <p>
                剩余每日任务：&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{
                                plug_makes.task_complete_day
                                }}
              </p>
              <p>
                剩余每周任务：&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{
                                plug_makes.task_complete_weeks
                                }}
              </p>
              <p>
                剩余每季任务：&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{
                                plug_makes.task_complete_season
                                }}
              </p>
            </div>
            <div class="clear"></div>
          </div>
          <div class="exp">
            <div class="exp-bar">
              <span class="exp-current"
                    :style="plug_makes.progress_exp"></span>
            </div>
            <div class="exp-num">
              EXP : <span class="exp-val">{{ plug_makes.plug_makes_exp }}</span>/{{ plug_makes.exp }}
            </div>
          </div>
        </div>
      </div>
      <div class="page page-1 active">
        <div class="gift">
          <div class="gift-left">
            <div class="gift-item gift-item-let">
              <div class="gift-title">Lv</div>
              <div class="gift-type-0"><span class="type type-0"></span></div>
              <div class="gift-type-1">
                <span class="type type-1"></span>
                <div class="item-btn cera">
                  <a class="btn cera"
                     href="javascript:;"
                     @click="BuyDisplay = 'display:block'">&nbsp;购买</a>
                </div>
              </div>
            </div>
          </div>
          <div class="gift-mid">
            <div class="gift-list">
              <!--level载入-->
            </div>
          </div>
          <div class="gift-right">
            <div class="gift-item gift-item-right">
              <!--Max载入-->
            </div>
          </div>
          <div class="gift-bottom">
            <div class="gift-roll">
              <span class="roll roll-left"></span>
              <div class="roll-bar"><span id="roll"></span></div>
              <span class="roll roll-right"></span>
            </div>
            <div class="gift-all-receive">
              <a class="btn"
                 href="javascript:;"
                 @click="all">全部领取</a>
            </div>
          </div>
        </div>
      </div>

      <div class="page page-2">
        <div class="tab tab-mid">
          <div class="tab-item midtab active">每日任务</div>
          <div class="tab-item midtab">每周任务</div>
          <div class="tab-item midtab">本期任务</div>
          <div>
            <span style="float: right; line-height: 26px">如任务已完成，但探险不显示,尝试退出游戏重新进入</span>
          </div>
        </div>
        <div class="quest-page">
          <div class="quest-page-item active">
            <div class="tr">
              <span class="th-a">任务内容</span><span>完成次数</span><span>经验值</span><span>状态</span>
            </div>
            <table>
              <tr class="tt"
                  v-for="(v, i) in plug_makes.plug_makes_task_day"
                  :key="i">
                <td class="td-a">{{ v.name }}</td>
                <td>{{ v.complete }} / {{ v.num }}</td>
                <td>
                  {{ v.exp }} EXP
                  <span v-if="plug_makes.plug_makes_type == 1">x2</span>
                </td>
                <td v-if="v.state == 1">
                  <span class="complete">已完成</span>
                </td>
                <td v-else-if="v.state == 0 && v.complete < v.num">
                  <span class="complete"
                        style="color: #a97a00">进行中</span>
                </td>
                <td v-else-if="v.state == 0 && v.complete >= v.num">
                  <a class="btn"
                     href="javascript:;"
                     @click="taskClick(v.id)">领取</a>
                </td>
              </tr>
            </table>
          </div>
          <div class="quest-page-item">
            <div class="tr">
              <span class="th-a">任务内容</span><span>完成次数</span><span>经验值</span><span>状态</span>
            </div>
            <table>
              <tr class="tt"
                  v-for="(v, i) in plug_makes.plug_makes_task_weeks"
                  :key="i">
                <td class="td-a">{{ v.name }}</td>
                <td>{{ v.complete }} / {{ v.num }}</td>
                <td>
                  {{ v.exp }} EXP
                  <span v-if="plug_makes.plug_makes_type == 1">x2</span>
                </td>
                <td v-if="v.state == 1">
                  <span class="complete">已完成</span>
                </td>
                <td v-else-if="v.state == 0 && v.complete < v.num">
                  <span class="complete"
                        style="color: #a97a00">进行中</span>
                </td>
                <td v-else-if="v.state == 0 && v.complete >= v.num">
                  <a class="btn"
                     href="javascript:;"
                     @click="taskClick(v.id)">领取</a>
                </td>
              </tr>
            </table>
          </div>
          <div class="quest-page-item">
            <div class="tr">
              <span class="th-a">任务内容</span><span>完成次数</span><span>经验值</span><span>状态</span>
            </div>
            <table>
              <tr class="tt"
                  v-for="(v, i) in plug_makes.plug_makes_task_season"
                  :key="i">
                <td class="td-a">{{ v.name }}</td>
                <td>{{ v.complete }} / {{ v.num }}</td>
                <td>
                  {{ v.exp }} EXP
                  <span v-if="plug_makes.plug_makes_type == 1">x2</span>
                </td>
                <td v-if="v.state == 1">
                  <span class="complete">已完成</span>
                </td>
                <td v-else-if="v.state == 0 && v.complete < v.num">
                  <span class="complete"
                        style="color: #a97a00">进行中</span>
                </td>
                <td v-else-if="v.state == 0 && v.complete >= v.num">
                  <a class="btn"
                     href="javascript:;"
                     @click="taskClick(v.id)">领取</a>
                </td>
              </tr>
            </table>
          </div>
        </div>
      </div>

      <div class="page page-3">
        <div class="tab tab-mid">
          <div class="tab-item exchange active">钻石充值</div>
          <div class="tab-item exchange">充值记录</div>
        </div>
        <div class="quest-page">
          <div class="quest-page-exchange active">
            <div class="col-sm-12"
                 style="margin-top: 4px; text-align: center">
              <p style="padding: 84px 0px">
                <input v-model="cdk"
                       placeholder="请输入钻石充值卡密"
                       class="service__input"
                       style="
                    height: 36px;
                    width: 410px;
                    border: 1px solid #678267;
                    background: #c5b48e;
                  " /><span @click="search"
                      style="
                    cursor: pointer;
                    padding: 13px 14px;
                    background: rgb(155 109 0);
                    border: 1px solid #000;
                    border-radius: 3px;
                  ">
                  兑换</span>
              </p>
            </div>
          </div>
          <div class="quest-page-exchange">
            <div class="tr">
              <span class="th-a">卡密</span><span>钻石面值</span><span style="width: 200px">兑换时间</span>
            </div>
            <table>
              <tr class="tt"
                  v-for="(v, i) in plug_makes.plug_makes_task_log"
                  :key="i">
                <td class="td-a">{{ v.camilo }}</td>
                <td>{{ v.diamond }}</td>
                <td style="width: 190px">{{ v.time }}</td>
              </tr>
            </table>
          </div>
        </div>
      </div>
    </div>
    <div class="pop">
      <div class="alert_bg pop-buy"
           :style="BuyDisplay">
        <div class="alert_main">
          <div class="alert_title"><span>购买</span></div>
          <div class="alert_con">
            <div class="alert_com">
              <div v-if="plug_makes.adventure == 0 || plug_makes.adventure == 1">
                <div style="background: #39569a">普通探险家资格证</div>
                <div style="border: 1px dashed #444; position: relative">
                  <div style="
                      width: 78%;
                      display: inline-block;
                      float: left;
                      text-align: left;
                      padding: 10px 10px;
                      color: #ff7878;
                    ">
                    <p>价格：{{ plug_makes.ordinaryAdventure }}钻石</p>
                    <p>
                      购买后可享受高级探险家等级关卡+任务经验2倍，如等级未满{{
                                            plug_makes.plug_makes_level_max
                                            }}级可以继续购买高级探险家礼包
                    </p>
                  </div>
                  <div style="
                      display: inline-block;
                      float: right;
                      position: absolute;
                      top: 48px;
                      right: 4px;
                    ">
                    <a class="btn cera"
                       href="javascript:;"
                       @click="cera('vip')">&nbsp;购买</a>
                    <hr />
                  </div>
                </div>
              </div>
              <div style="margin-top: 112px"
                   v-if="plug_makes.adventure == 0 || plug_makes.adventure == 2">
                <div style="background: #39569a">高级探险家礼包</div>
                <div style="border: 1px dashed #444; position: relative">
                  <div style="
                      width: 78%;
                      display: inline-block;
                      float: left;
                      text-align: left;
                      padding: 10px 10px;
                      color: #ff7878;
                    ">
                    <p>价格：{{ plug_makes.seniorAdventure }}钻石</p>
                    <p>
                      享高级探险家+直升Lv{{
                                            plug_makes.seniorLevel
                                            }}+任务经验2倍
                    </p>
                  </div>
                  <div style="
                      display: inline-block;
                      float: right;
                      position: absolute;
                      top: 32px;
                      right: 4px;
                    ">
                    <a class="btn cera"
                       href="javascript:;"
                       @click="cera('svip')">&nbsp;购买</a>
                    <hr />
                  </div>
                </div>
              </div>

              <div style="margin-top: 92px">
                <div style="background: #39569a">等级购买</div>
                <div style="border: 1px dashed #444; position: relative">
                  <div style="
                      width: 78%;
                      display: inline-block;
                      float: left;
                      text-align: left;
                      padding: 10px 10px;
                      color: #ff7878;
                    ">
                    <p style="margin-bottom: 4px">
                      价格：{{ plug_makes.level_num }}钻石
                    </p>
                    <input type="text"
                           v-model="level_num"
                           placeholder="输入需要购买的等级数" />
                  </div>
                  <div style="
                      display: inline-block;
                      float: right;
                      position: absolute;
                      top: 32px;
                      right: 4px;
                    ">
                    <a class="btn cera"
                       href="javascript:;"
                       @click="cera('level')">&nbsp;购买</a>
                    <hr />
                  </div>
                </div>
              </div>
              <div class="btn"
                   @click="BuyDisplay = 'display:none'"
                   style="margin-top: 64px">
                关闭
              </div>
            </div>
          </div>
          <div class="alert_bottom"><span></span></div>
        </div>
      </div>
    </div>
    <div class="iteminfo"></div>
    <div class="alert">
      <!-- 购买 -->
      <div class="alert_bg"
           :style="PayDisplay">
        <div class="alert_main">
          <div class="alert_title"><span>提示</span></div>
          <div class="alert_con">
            <div class="alert_com">
              <span>复制本链接到浏览器中打开</span>
              <input style="width: 96%"
                     type="text"
                     :value="plug_makes.pay_url" />
              <div class="btn"
                   @click="PayDisplay = 'display:none'"
                   style="margin-top: 10px">
                关闭
              </div>
            </div>
          </div>
          <div class="alert_bottom"><span></span></div>
        </div>
      </div>

      <!-- 弹出框 -->
      <div class="alert_bg"
           :style="MessageDisplay">
        <div class="alert_main">
          <div class="alert_title"><span>提示</span></div>
          <div class="alert_con">
            <div class="alert_com">
              <span>{{ MessageMsg }}</span>
              <div class="btn"
                   @click="CloseAlert">关闭</div>
            </div>
          </div>
          <div class="alert_bottom"><span></span></div>
        </div>
      </div>
      <div class="alert_bg"
           :style="SuccessDisplay">
        <div class="alert_main">
          <div class="alert_title"><span>提示</span></div>
          <div class="alert_con">
            <div class="alert_com">
              <span>{{ MessageMsg }}</span>
              <div class="btn"
                   @click="SuccessAlert">关闭</div>
            </div>
          </div>
          <div class="alert_bottom"><span></span></div>
        </div>
      </div>
      <!-- 弹出框end -->
      <!-- 登陆框 -->
      <div class="alert_bg"
           :style="LoginDisplay">
        <div class="alert_main"
             style="margin-top: 86px; width: 350px">
          <div class="alert_title"><span>绑定|切换</span></div>
          <div class="alert_con">
            <div class="alert_com">
              <p style="margin-bottom: 6px; display: flex">
                <span style="padding: 0px 0px 0px 42px">游戏账号：</span><input type="text"
                       v-model="login_info.username"
                       class="service__input" />
              </p>
              <p style="margin-bottom: 6px; display: flex">
                <span style="padding: 0px 0px 0px 42px">游戏密码：</span><input type="password"
                       v-model="login_info.password"
                       class="service__input" />
              </p>
              <p style="margin-bottom: 6px; display: flex">
                <span style="padding: 0px 0px 0px 42px">绑定角色：</span>
                <select v-model="login_info.role"
                        style="width: 174px">
                  <option v-for="(itmec, i) in role_data"
                          :key="i"
                          :value="itmec.charac_no">
                    {{ itmec.charac_name }}
                  </option>
                </select>
                <span style="
                    background: #7478e4;
                    padding: 3px 10px;
                    margin-left: 2px;
                    border-radius: 4%;
                  "
                      @click="getRole">获取</span>
              </p>
              <div>
                <span style="padding: 0px 0px; color: #f90000"><span style="display: inline-block; padding: 0px 0px">{{
                                        LoginError
                                        }}</span></span>
              </div>
              <div style="text-align: center; padding: 10px 0px">
                <div class="btn"
                     @click="login"
                     style="margin: 0px 0px; display: inline-block">
                  绑定
                </div>
                <div class="btn"
                     @click="LoginDisplay = 'display:none'"
                     style="margin: 0px 0px; display: inline-block">
                  取消
                </div>
              </div>
            </div>
          </div>
          <div class="alert_bottom"><span></span></div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import $ from "jquery";
import Vue from "vue";

export default {
  name: "Makes",
  data () {
    return {
      MessageDisplay: "display:none", //弹出框显示
      SuccessDisplay: "display:none", //成功弹出框
      LoginDisplay: "display:none", //登陆弹出框
      PayDisplay: "display:none", //购买弹出框
      BuyDisplay: "display:none", //升级弹出框
      LoginError: "",
      MessageMsg: "", //弹出框消息
      Loading: false,
      plug_makes: {
        m_id: 0,
        charac_no: 0,
        charac_name: "",
        exp: 0,
        ordinaryAdventure: 0,
        seniorAdventure: 0,
        seniorLevel: 0,
        kf_day: 0,
        season: 1,
        plug_makes_exp: 0,
        plug_makes_level: 0,
        plug_makes_type: 0,
        progress_exp: 0,
        plug_makes_level_max: 0,
        makes_type_award: [],
        level_num: 0,
        currency: "",
        adventure: 0,
        plug_makes_task_day: [],
        plug_makes_task_weeks: [],
        plug_makes_task_season: [],
        task_complete_day: "0 / 0",
        task_complete_weeks: "0 / 0",
        task_complete_season: "0 / 0",
        diamond: 0, //钻石
        plug_makes_task_log: [],
        pay_url: "",
        roomdata: [],
        type: 0,
      },
      login_info: {
        username: "",
        password: "",
        role: "",
      },
      cdk: "",
      level_num: 1,
      role_data: [],
    };
  },
  created () {
    this.getInfo();
  },
  mounted () {
    // 将vue中的方法赋值给window
    window.plug_makes = this.plug_makes;
    window.refresh = this.refresh;
    window.gift = this.gift;
    this.init();
  },
  methods: {
    //登陆
    login () {
      if (this.Loading == true) {
        return false;
      }
      this.Loading = true;
      this.axios({
        // 默认请求方式为get
        method: "post",
        url: Vue.prototype.config.webplugurl + "/PlugLogin",
        // 传递参数
        data: {
          key: this.$route.query.key,
          keyid:this.$route.query.id,
          username: this.login_info.username,
          password: this.login_info.password,
          role: this.login_info.role,
        },
        responseType: "json",
      })
        .then((response) => {
          this.Loading = false;
          if (response.data.code == 200) {
            this.LoginDisplay = "display:none";
            this.getInfo();
          } else {
            this.LoginError = response.data.msg;
          }
        })
        .catch((error) => {
          this.Loading = false;
          // 请求失败，
          console.log(error);
        });
    },
    //获取角色
    getRole () {
      if (this.Loading == true) {
        return false;
      }
      this.Loading = true;
      this.axios({
        // 默认请求方式为get
        method: "post",
        url: Vue.prototype.config.webplugurl + "/getRole",
        // 传递参数
        data: {
          key: this.$route.query.key,
          keyid:this.$route.query.id,
          username: this.login_info.username,
          password: this.login_info.password,
        },
        responseType: "json",
      })
        .then((response) => {
          this.Loading = false;
          if (response.data.code == 200) {
            this.role_data = response.data.info;
          } else {
            this.LoginError = response.data.msg;
          }
        })
        .catch((error) => {
          this.Loading = false;
          // 请求失败，
          console.log(error);
        });
    },
    //复制
    CloudDiskCopy(url) {
      console.log(url);
        //创建input标签
        var input = document.createElement('input')
        //将input的值设置为需要复制的内容
        input.value = url
        //添加input标签
        document.body.appendChild(input)
        //选中input标签
        input.select()
        //执行复制
        document.execCommand('copy')
        //成功提示信息
        this.PopMessage(false, '复制成功');
        //移除input标签
        document.body.removeChild(input)
      },
    //获取基本配置信息
    getInfo () {
      this.Loading = true;
      this.axios({
        // 默认请求方式为get
        method: "post",
        url: Vue.prototype.config.webplugurl + "/makes/getMakesInfo",
        // 传递参数
        data: {
          key: this.$route.query.key,
          keyid:this.$route.query.id,
        },
        responseType: "json",
      })
        .then((response) => {
          this.Loading = false;
          if (response.data.code == 200) {
            this.plug_makes = response.data.info;
            window.plug_makes = this.plug_makes;
            this.init();
          } else {
            this.PopMessage(true, response.data.msg);
          }
        })
        .catch((error) => {
          this.Loading = false;
          // 请求失败，
          console.log(error);
        });
    },

    //弹出框状态
    PopMessage (type, msg) {
      this.SuccessDisplay = "display:none";
      this.MessageDisplay = "display:none";
      this.MessageMsg = msg;
      if (type == false) {
        this.MessageDisplay = "display:block";
      } else {
        this.SuccessDisplay = "display:block";
      }
    },
    /*下面是数据处理区*/
    init () {
      let _this = this;
      $(".warp").removeClass("vip-0");
      $(".warp").removeClass("vip-1");
      console.log(_this.plug_makes.plug_makes_type);
      $(".warp").addClass("vip-" + _this.plug_makes.plug_makes_type);
      _this.refresh(_this.plug_makes.plug_makes_level - 1);
      var l =
        (380 / _this.plug_makes.plug_makes_level_max) *
        (_this.plug_makes.plug_makes_level - 1);
      if (l < 0) {
        l = 0;
      }
      if (l > 380) l = 380;
      $("#roll").css({ left: l + "px" });
      if (_this.plug_makes.makes_type_award["length"] > 0) {
        _this.refreshmax();
      }
    },
    refresh (id) {
      let _this = this;
      if (id < 0) id = 0;
      if (id > _this.plug_makes.plug_makes_level_max)
        id = _this.plug_makes.plug_makes_level_max;
      var html = "";
      if (_this.plug_makes.makes_type_award["length"] > 0) {
        for (var i = 0; i < 5; i++) {
          html += _this.createitem(_this.plug_makes.makes_type_award[id + i]);
        }
      }
      $(".gift-list").html(html);
    },
    refreshmax () {
      let _this = this;
      $(".gift-item-right").html(
        _this.createitem(
          _this.plug_makes.makes_type_award[
          _this.plug_makes.makes_type_award["length"] - 1
          ]
        )
      );
    },
    createitem (dat) {
      let _this = this;
      if (!dat) {
        return "";
      }
      var c =
        dat["level"] > _this.plug_makes.plug_makes_level ||
          (dat["level"] == 0 &&
            _this.plug_makes.plug_makes_level <
            _this.plug_makes.makes_type_award["length"])
          ? " close"
          : " open";
      var d =
        dat["level"] > _this.plug_makes.plug_makes_level ||
          (dat["level"] == 0 &&
            _this.plug_makes.plug_makes_level <
            _this.plug_makes.makes_type_award["length"])
          ? " disable"
          : "";
      var title =
        dat["level"] > 0 ? dat["level"] : _this.plug_makes.plug_makes_level_max;

      var html =
        '<div class="gift-item gift-item-let">' +
        '<div class="gift-title">' +
        title +
        "</div>" +
        '<div class="gift-type-0  ' +
        c +
        '">';
      /*初级奖励*/
      var btn = '<span class="item-received">已领取</span>'; //判断是否已领取
      if (!dat["received1"])
        btn =
          '<a class="btn' +
          d +
          '" href="javascript:gift(' +
          dat["level"] +
          ',0);">领取</a>';
      var itme = '<div class="item-lis"><ul>';
      $.each(dat["time1"], function (k, v) {
        itme +=
          '<li class="lj" itme-name="' +
          v.name +
          "x" +
          v.num +
          '"><img src="' +
          v.ico +
          '"></li>';
      });
      itme += "</ul></div>";
      html +=
        '<div class="gift-gameitem">' +
        itme +
        '<div class="item-btn" id="btn-' +
        dat["id"] +
        '-1">' +
        btn +
        "</div>" +
        "</div>" +
        '<span class="lock"></span>' +
        '<span class="mask"></span></div>';

      if (!_this.plug_makes.plug_makes_type) {
        c = " close";
        d = " disable";
      }
      html += '<div class="gift-type-1 ' + c + '">';
      btn = '<span class="item-received">已领取</span>';
      if (!dat["received2"])
        btn =
          '<a class="btn' +
          d +
          '" href="javascript:gift(' +
          dat["level"] +
          ',1);">领取</a>';

      if (!dat["received2"]);
      itme = '<div class="item-lis"><ul>';
      $.each(dat["time2"], function (k, v) {
        itme +=
          '<li class="lj" itme-name="' +
          v.name +
          "x" +
          v.num +
          '"><img src="' +
          v.ico +
          '" ></li>';
      });
      itme += "</ul></div>";
      html +=
        '<div class="gift-gameitem">' +
        itme +
        '<div class="item-btn" id="btn-' +
        dat["level"] +
        '-2">' +
        btn +
        "</div>" +
        "</div>" +
        '<span class="lock"></span>' +
        '<span class="mask"></span></div>';
      html += "</div>";
      return html;
    },
    //购买
    cera (lqtype) {
      if (this.Loading == true) {
        return false
      }
      this.Loading = true;
      this.axios({
        // 默认请求方式为get
        method: "post",
        url: Vue.prototype.config.webplugurl + "/makes/makesCera",
        // 传递参数
        data: {
          key: this.$route.query.key,
          keyid:this.$route.query.id,
          lqtype: lqtype,
          num: this.level_num,
        },
        responseType: "json",
      })
        .then((response) => {
          this.Loading = false;
          if (response.data.code == 200) {
            this.PopMessage(true, response.data.msg);
          } else {
            this.PopMessage(false, response.data.msg);
          }
        })
        .catch((error) => {
          this.Loading = false;
          // 请求失败，
          console.log(error);
        });
    },
    gift (level, type) {
      if (this.Loading == true) {
        return false
      }
      this.Loading = true;
      this.axios({
        // 默认请求方式为get
        method: "post",
        url: Vue.prototype.config.webplugurl + "/makes/MakesLevelGift",
        // 传递参数
        data: {
          key: this.$route.query.key,
          keyid:this.$route.query.id,
          lqtype: type,
          level: level,
        },
        responseType: "json",
      })
        .then((response) => {
          this.Loading = false;
          if (response.data.code == 200) {
            this.PopMessage(true, response.data.msg);
          } else {
            this.PopMessage(false, response.data.msg);
          }
        })
        .catch((error) => {
          this.Loading = false;
          // 请求失败，
          console.log(error);
        });
    },
    //全部领取
    all () {
      if (this.Loading == true) {
        return false
      }
      this.Loading = true;
      this.axios({
        // 默认请求方式为get
        method: "post",
        url: Vue.prototype.config.webplugurl + "/makes/MakesLevelAll",
        // 传递参数
        data: {
          key: this.$route.query.key,
          keyid:this.$route.query.id,
        },
        responseType: "json",
      })
        .then((response) => {
          this.Loading = false;
          if (response.data.code == 200) {
            this.PopMessage(true, response.data.msg);
          } else {
            this.PopMessage(false, response.data.msg);
          }
        })
        .catch((error) => {
          this.Loading = false;
          // 请求失败，
          console.log(error);
        });
    },
    //充值
    search () {
      if (this.Loading == true) {
        return false
      }
      this.Loading = true;
      this.axios({
        // 默认请求方式为get
        method: "post",
        url: Vue.prototype.config.webplugurl + "/makes/MakesCdkExchange",
        // 传递参数
        data: {
          key: this.$route.query.key,
          keyid:this.$route.query.id,
          cdk: this.cdk,
        },
        responseType: "json",
      })
        .then((response) => {
          this.Loading = false;
          if (response.data.code == 200) {
            this.PopMessage(true, response.data.msg);
          } else {
            this.PopMessage(false, response.data.msg);
          }
        })
        .catch((error) => {
          this.Loading = false;
          // 请求失败，
          console.log(error);
        });
    },
    taskClick (id) {
      if (this.Loading == true) {
        return false
      }
      this.Loading = true;
      this.axios({
        // 默认请求方式为get
        method: "post",
        url: Vue.prototype.config.webplugurl + "/makes/MakesTaskClick",
        // 传递参数
        data: {
          key: this.$route.query.key,
          keyid:this.$route.query.id,
          id: id,
        },
        responseType: "json",
      })
        .then((response) => {
          this.Loading = false;
          if (response.data.code == 200) {
            this.PopMessage(true, response.data.msg);
          } else {
            this.PopMessage(false, response.data.msg);
          }
        })
        .catch((error) => {
          this.Loading = false;
          // 请求失败，
          console.log(error);
        });
    },
    //关闭窗口
    CloseAlert () {
      this.SuccessDisplay = "display:none";
      this.MessageDisplay = "display:none";
    },
    SuccessAlert () {
      this.SuccessDisplay = "display:none";
      this.MessageDisplay = "display:none";
      this.getInfo();
    },
  },
};

$(function () {
  var $div = $("#roll");
  $div.bind("mousedown", function (event) {
    var x = $(this)[0].offsetLeft;
    var mouse_x = event.pageX;
    $(document).bind("mousemove", function (e) {
      var _x = e.pageX - mouse_x;
      var now_x = x + _x;
      if (now_x < 0) now_x = 0;
      if (now_x > 380) now_x = 380;

      var n = now_x / (380 / window.plug_makes.plug_makes_level_max);
      window.refresh(Math.round(n));
      $div.css({ left: now_x + "px" });
    });
  });
  $(document).bind("mouseup", function () {
    $(this).unbind("mousemove");
  });
  $(".main").on("mouseenter", ".item-name", function () {
    $(".titlehover")
      .off()
      .html($(this).html())
      .css({
        top: $(this).offset().top - 3,
        left: $(this).offset().left - 3,
      })
      .fadeIn(100)
      .one("mouseleave", function () {
        $(this).fadeOut(100);
      });
  });

  $(".exchange").click(function () {
    var index = $(this).index();
    $(".quest-page-exchange").removeClass("active");
    $(".exchange").removeClass("active");
    $(this).addClass("active");
    $(".quest-page-exchange").eq(index).addClass("active");
  });

  $(".toptab").click(function () {
    var index = $(this).index();
    $(".page").removeClass("active");
    $(".toptab").removeClass("active");
    $(this).addClass("active");
    $(".page").eq(index).addClass("active");
  });
  $(".midtab").click(function () {
    var index = $(this).index();
    $(".quest-page-item").removeClass("active");
    $(".midtab").removeClass("active");
    $(this).addClass("active");
    $(".quest-page-item").eq(index).addClass("active");
  });

  $("body").on("mouseenter", ".lj", function () {
    lj_info($(this).attr("itme-name"));
    let x = $(this).offset().left;
    let y = $(this).offset().top;
    let X = $(this).parent().position().left;
    if (X > 170) {
      $(".iteminfo").css("left", x - 154);
    } else {
      $(".iteminfo").css("left", x + 30);
    }
    $(".iteminfo").css("top", y);
    $(".iteminfo").css("display", "block");
    let item_h = $(".iteminfo").height() + $(".iteminfo").position().top;
    if (item_h > 435) {
      y -= item_h - 435;
      $(".iteminfo").css("top", y);
    }
  });
  $("body").on("mouseleave", ".lj", function () {
    $(".iteminfo").css("display", "none");
  });
  function lj_info (name) {
    var dat = "item_error";
    dat =
      '<div class="item_info"><div class="flavor" style="text-align:center;">' +
      name +
      "</div>";
    $(".iteminfo").html(dat);
  }
});
</script>
<style scoped>
@import "../../../public/static/plug/makes/static/css/game.css";
</style>

<style>
#root,
body,
html {
  min-width: 0px;
}
</style>
